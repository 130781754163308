import React from 'react';
import Button from "../../utils/Button";
import mannuel from "../../../img/mannuel_about.jpg";

const AboutMeIntro = () => {
    return (
        <div className="flex items-center flex-col px-4 md:px-0 pt-12 md:pt-0">
            <div className="flex-col md:flex-row md:h-screen flex justify-between md:mx-28 md:pt-20 md:mb-36">
                <div className="md:w-1/2 md:ml-10">
                    <div className="w-full md:w-fit">
                        <div className="flex justify-center w-full md:mannuel-box-shadow">
                            <img className="h-[22rem] md:h-[600px] md:w-[450px] object-cover relative z-10" src={mannuel} alt="Best Photographer in Nairobi"/>
                        </div>
                    </div>
                </div>
                <div className="md:w-3/5 pt-5 md:pt-0">
                    <div className="pb-5">
                        <h5 className="text-center md:text-left font-cormorantGaramond text-3xl md:text-5xl text-vol md:leading-[72px]">
                            Hi, my
                            name
                            is
                            Emannuel</h5>
                    </div>
                    <div className="md:pb-10 md:pl-10">
                        <p className="font-light font-worksans leading-7">
                            I am Emmanuel Mutisya,
                            <br/>
                            a passionate visual storyteller dedicated to capturing the emotional
                            essence of every moment.
                            <br/>
                            Specializing in weddings, fashion, and documentaries
                            <br/>
                            my bespoke approach transforms fleeting moments into timeless narratives.
                            <br/>
                            I believe in the power of visuals to evoke feelings and tell stories that resonate deeply.
                            <br/>
                            Join me in creating art that reflects your unique journey.
                        </p>
                    </div>
                    <div className="pt-5 md:pt-0 md:pb-8 md:pl-20">
                        <a href="/about">
                            <Button text={"More About Me"}/>
                        </a>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default AboutMeIntro;