import React from 'react';
import about_me_img from "../../../img/about/mannuel-minif.jpg";
import Button from "../../utils/Button";
import Paragraph from "../../utils/Paragraph";

const MyStory = () => {
   return (
       <div className="container-main h-[140%] relative py-14 md:py-[10vh]">
          <div className="container-content-wrapper flex flex-col md:flex-row relative z-[3] px-6 md:px-16 h-full">
             <div className="title-container flex flex-col justify-end items-start w-full md:w-1/2 md:pt-0 z-[9]">
                <h2 className="font-vollkorn text-vol text-3xl mb-4 md:mb-0 w-full md:text-7xl md:w-3/4">
                   Before you share your story..
                </h2>
                <div className="top-[168%] md:top-0 absolute md:static">
                   <a href="#section3-about" className="transition ease-in-out duration-500">
                      <Button classes={"text-xs"} text={"Learn More"}/>
                   </a>
                </div>
             </div>
             <div className="about-photo-container flex justify-center w-full left-0 md:left-1/2 relative text-center md:absolute top-0 z-[2]">
                <img className="h-[290px] w-[220px] md:h-[29.16666667vw] object-cover md:w-[22.61904762vw]" alt="Mannuel Portraits portfolio picture" src={about_me_img}/>
             </div>
             <div className="text-container-main py-16 md:py-0 md:flex md:justify-end md:items-end md:w-1/2 mb-[90px] h-full z-[2]">
                <div
                    className="flex flex-col text-container md:max-w-[280px] absolute md:static lg:max-w-[28.80952381vw] h-full">
                   <h4 className="uppercase font-cprime text-vol mb-5 md:mb-[90px]">let me tell you mine..</h4>
                   <p className="font-light font-worksans leading-7">
                      I am Emmanuel Mutisya, a passionate visual storyteller
                      dedicated to capturing the emotional essence of every moment.
                      <br/>
                      Specializing in weddings, fashion, and documentaries, my bespoke
                      approach transforms fleeting moments into timeless narratives.
                      <br/>
                      I believe in the power of visuals to evoke feelings and tell
                      stories that resonate deeply.
                      <br/>
                      Join me in creating art that reflects your unique journey.
                   </p>
                </div>
             </div>
          </div>
          <div className="container-text-background h-[130%] md:h-full absolute right-0 top-60 md:top-0 z-[1] w-full md:w-1/2 bg-sect"></div>
          <div className="bg-line h-full w-full"></div>
       </div>
   );
};

export default MyStory;
