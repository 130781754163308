import React, { useEffect, useState } from 'react';
import PortfolioItem from "../PortfolioItem";
import './loader.css'; // Assuming your CSS for the loader is in this file
import { ClipLoader } from 'react-spinners';
import { css } from '@emotion/react';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black; /* Set the border color to black */
`;

const Weddings = () => {
   const [images, setImages] = useState([]);
   const [loading, setLoading] = useState(true); // Add loading state
   

   useEffect(() => {
      const fetchData = async () => {
         try {
            const response = await fetch("https://mannuelportraits.up.railway.app/api/photo-list");
            const data = await response.json();
            const filteredImages = data.map(item => ({ id: item.id, imgSrc: item.image, description: item.description }));
            setImages(filteredImages);
            setLoading(false); // Set loading to false after images are fetched
         } catch (error) {
            console.error("Error fetching images:", error);
            setLoading(false); // Set loading to false even if there is an error
         }
      };

      fetchData();
   }, []); // Empty dependency array ensures useEffect runs only once on component mount

   return (
       <>
          {loading ? (
            <div className="col-span-3 flex justify-center items-center">
               <ClipLoader color="black" css={override} size={30} />
            </div>
          ) : (
              <PortfolioItem images={images} />
          )}
       </>
   );
};

export default Weddings;
