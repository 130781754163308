import React from 'react';
import PageLinks from "./PageLinks";
import ContactMeIntro from "./ContactMeIntro";
// import BlogHome from "./../blog/BlogHome";
import LandingCarousel from './LandingCarousel';
import AboutMeIntro from "./AboutMeIntro";


const LandingPage = () => {
   return (
       <>
         <LandingCarousel/>
           <AboutMeIntro/>
          {/*<BlogHome/>*/}
          <PageLinks />
          <ContactMeIntro />
       </>
   );
};

export default LandingPage;
