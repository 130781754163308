import React from 'react';
import about_me_img from "../../../img/about/about-me-min.jpg";
import wedding_two_beach from "../../../img/bg-maasai/maasai-bg-desktop-min.jpg";
import jumping_masais from "../../../img/bg-maasai/jumping-masais.jpg";
import LinkButtonOthers from "../../utils/LinkButtonOthers";

const PageLinks = () => {
   return (
       <div className="md:w-full md:h-full px-4 md:px-16 md:mb-32 md:pb-24">
          <div className="w-full h-full flex justify-center mb-20">
             <div className="flex flex-col md:flex-row space-y-5 md:space-y-0 md:space-x-10 h-5/6 font-co">
                <div className="md:w-1/3 md:bg-black">
                   <a href="/portfolio">
                      <img className="h-[50vh] md:h-full w-full object-cover opacity-80" src={wedding_two_beach}/>
                      <div className="py-6 md:pt-7 text-center">
                         <h3 className="text-4xl font-cormorantGaramond">Gallery</h3>
                         <p className="pt-3 uppercase font-cprime font-light tracking-widest">See My Work</p>
                         <div className="mt-7">
                            <LinkButtonOthers href="/portfolio" text="View Portfolio"/>
                         </div>
                      </div>
                   </a>
                </div>
                <div className="md:w-1/3">
                   <a href="/videography">
                      <img className="h-[50vh] md:h-full w-full object-cover" src={about_me_img}/>
                      <div className="py-6 md:pt-7 text-center">
                         <h3 className="text-4xl font-cormorantGaramond">Films</h3>
                         <p className="pt-3 uppercase font-cprime font-light tracking-widest">
                            Videography and Documentary
                         </p>
                         <div className="mt-7">
                            <LinkButtonOthers href="/videography" text="View Videography"/>
                         </div>
                      </div>
                   </a>
                </div>
                <div className="md:w-1/3">
                   <a href="/packages">
                      <img className="h-[50vh] md:h-full w-full object-cover" src={jumping_masais}/>
                      <div className="pt-7 text-center">
                         <h3 className="text-4xl font-cormorantGaramond">Pricing</h3>
                         <p className="pt-3 uppercase font-cprime font-light tracking-widest">
                            Packages and pricing
                         </p>
                         <div className="mt-7">
                            <LinkButtonOthers href="/packages" text="View Packages"/>
                         </div>
                      </div>
                   </a>
                </div>
             </div>
          </div>
       </div>
   )
       ;
};

export default PageLinks;
