import React, { useEffect, useState } from 'react';
import Carousel from "../../utils/carousel.component";
import "../../../App.css";

function LandingCarousel() {
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    async function fetchImages() {
      try {
        const response = await fetch('https://mannuelportraits.up.railway.app/api/carousel-images');
        const data = await response.json();
        // Extract both image and description
        const imagesWithDescriptions = data.map(item => ({
          image: item.image,
          description: item.description
        }));
        setSlides(imagesWithDescriptions);
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    }

    fetchImages();
  }, []);

  return (
      <div className="">
        {/* Pass both image and description to the Carousel component */}
        <Carousel slides={slides} />
      </div>
  );
}

export default LandingCarousel;
